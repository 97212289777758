import React from 'react'
import { Link } from 'gatsby'

import { config, useSpring, animated } from 'react-spring'
import { readableColor } from 'polished'
import styled from 'styled-components'
import Layout from '../components/layout'
import { AnimatedBox, Button } from '../elements'
import SEO from '../components/SEO'

const PButton = styled(Button)<{ color: string }>`
  background: ${props => (props.color === 'white' ? 'black' : props.color)};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
`
const Description = styled(animated.div)`
  max-width: 960px;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
  padding-top: 0.1em;
`

const Booking = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const descAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })

  return (
    <Layout color="#D9FFFF">
      <SEO title="Booking | Kiki" desc="Kiki's Tattoo Service | @ink.by.kiki | Vancouver, BC" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 10]}>
        <animated.h1 style={titleAnimation}>Booking</animated.h1>
        <Description style={descAnimation}>
          <h4>For booking <u>flash & custom designs</u>, please see <a href="https://www.tat2market.com/artists/ink.by.kiki" title="booking flash designs">Tat2Market.</a></h4>
        
          <h4>Things to note:</h4>

          <ul>
            <li>I no longer do booking through my website (do read what's below, because it's applicable to my booking form). For all requests, custom and flash alike, please see <a href="https://www.tat2market.com/artists/ink.by.kiki" title="booking flash designs">Tat2Market.</a></li>
            <li>You must be <b>18+</b> to be tattooed.</li>
            <li>Reference images (for placement, style, composition, etc.) are very helpful!</li>
            <li>If requesting a tattoo in a location with existing tattoos, please include images of the existing pieces.</li>

          </ul>
          <p>
          If your custom request is accepted, you will be notified through Tat2market. Consultation will take place over chat on the website. Please allow for anywhere from a couple days to a couple weeks to hear from me in response to your submission. You will receive a quote for your tattoo once the concept has been fleshed out. Make sure to note that you will be charged GST on top of your quote. Your quote may be re-worked if the direction of your design changes.
          </p>
          <p>
          A non-refundable deposit will be required to secure an appointment. This deposit will go towards the total cost of your tattoo and will be forfeited if you cancel, do not show to your appointment, or fail to reschedule 24 hours prior to your appointment. 
          </p>
          <p>
          For any questions or concerns, feel free to reach me at <b>kiki@inkbykiki.com</b>.
          </p>
        </Description>
      </AnimatedBox>
    </Layout>
  )
}

export default Booking
